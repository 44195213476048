import { init as initAmplitude } from "@amplitude/analytics-browser"
import { ChakraBaseProvider } from "@chakra-ui/react"
import {
  ClerkLoaded,
  ClerkLoading,
  ClerkProvider,
  useUser,
} from "@clerk/clerk-react"
import { QueryClientProvider } from "@tanstack/react-query"
import dayjs from "dayjs"
import "dayjs/locale/fr"
import LocalizedFormat from "dayjs/plugin/localizedFormat"
import { PostHogProvider, usePostHog } from "posthog-js/react"
import { useEffect } from "react"
import { ErrorBoundary } from "react-error-boundary"
import { RouterProvider } from "react-router-dom"
import { theme } from "@bleu/front/assets/theme"
import { CLERK_COPY, CLERK_THEME } from "@bleu/front/assets/theme/clerk"
import { FullPageLoader } from "@bleu/front/components/layout/FullPageLoader"
import { queryClient } from "@bleu/front/lib/queryClient"
import { router } from "@bleu/front/pages/router"
import { ErrorBoundaryFallback } from "./ErrorBoundaryFallback"
import "./global.css"
import { initFacebookPixel } from "./utils/facebookPixel"
import { initializeDevice, setAmplitudeUserId } from "./utils/tracking"

dayjs.extend(LocalizedFormat)
dayjs.locale("fr")

const CLERK_PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY
const POSTHOG_KEY = import.meta.env.VITE_POSTHOG_KEY
const POSTHOG_HOST = import.meta.env.VITE_POSTHOG_HOST
const VITE_API_URL = import.meta.env.VITE_API_URL
const GTM_ID = import.meta.env.VITE_GTM_ID
const AMPLITUDE_API_KEY = import.meta.env.VITE_AMPLITUDE_API_KEY

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isPostHogLoaded = typeof window !== "undefined" && (window as any).posthog

if (!CLERK_PUBLISHABLE_KEY) {
  throw new Error("Missing Clerk Publishable Key")
}

if (!POSTHOG_KEY || !POSTHOG_HOST) {
  throw new Error("Missing PostHog configuration")
}

if (!GTM_ID) {
  throw new Error("Missing Google Tag Manager ID")
}

if (!AMPLITUDE_API_KEY) {
  throw new Error("Missing Amplitude API Key")
}

// Define the default PostHog endpoint
const DEFAULT_POSTHOG_ENDPOINT = "https://us.i.posthog.com"

/**
 * Function to determine the appropriate PostHog endpoint
 */
function getPostHogEndpoint() {
  const isDev = import.meta.env.DEV
  const isStaging = import.meta.env.VITE_IS_STAGING === "true"

  if (isDev || isStaging) {
    return DEFAULT_POSTHOG_ENDPOINT
  }

  if (VITE_API_URL) {
    return `${VITE_API_URL}/collect`
  }

  console.warn("VITE_API_URL is not set. Using default PostHog endpoint.")
  return DEFAULT_POSTHOG_ENDPOINT
}

/**
 * Component to initialize Amplitude SDK
 */
function AmplitudeInitializer() {
  useEffect(() => {
    initAmplitude(AMPLITUDE_API_KEY, undefined, {
      serverZone: "EU",
      // Add any additional configuration options here
    })
    initializeDevice()
  }, [])

  return null
}

/**
 * Component to initialize Google Analytics
 */
function GoogleAnalyticsInitializer() {
  useEffect(() => {
    // Initialize dataLayer
    window.dataLayer = window.dataLayer || []
  }, [])

  return null
}

/**
 * Component to initialize Facebook Pixel, PostHog, Google Tag Manager, with user data.
 */
function UserDataInitializer() {
  const { user, isLoaded } = useUser()
  const posthog = usePostHog()

  useEffect(() => {
    if (isLoaded && user) {
      // Initialize Facebook Pixel
      initFacebookPixel(user.primaryEmailAddress?.emailAddress)

      // Initialize PostHog
      if (posthog && !posthog._isIdentified()) {
        posthog.identify(user.id, {
          id: user.id,
        })
      }

      // Set user ID in Amplitude using PostHog distinct ID
      const posthogDistinctId = posthog.get_distinct_id()
      setAmplitudeUserId(posthogDistinctId)
    }

    // Reset PostHog identification if user is not available
    if (isLoaded && !user && posthog?._isIdentified()) {
      posthog.reset()
    }
  }, [isLoaded, user, posthog])

  return null
}

function App() {
  // Don't initialize PostHog if it's already loaded
  if (isPostHogLoaded) {
    console.warn("PostHog is already initialized. Skipping re-initialization.")
    return (
      <ClerkProvider
        localization={CLERK_COPY}
        appearance={CLERK_THEME}
        publishableKey={CLERK_PUBLISHABLE_KEY}
      >
        <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
          <ChakraBaseProvider theme={theme}>
            <AmplitudeInitializer />
            <GoogleAnalyticsInitializer />
            <ClerkLoaded>
              <QueryClientProvider client={queryClient}>
                <UserDataInitializer />
                <RouterProvider router={router} />
              </QueryClientProvider>
            </ClerkLoaded>
            <ClerkLoading>
              <FullPageLoader />
            </ClerkLoading>
          </ChakraBaseProvider>
        </ErrorBoundary>
      </ClerkProvider>
    )
  }

  return (
    <ClerkProvider
      localization={CLERK_COPY}
      appearance={CLERK_THEME}
      publishableKey={CLERK_PUBLISHABLE_KEY}
    >
      <PostHogProvider
        apiKey={POSTHOG_KEY}
        options={{
          api_host: getPostHogEndpoint(),
          autocapture: false,
          capture_pageview: true,
          capture_pageleave: true,
          capture_heatmaps: false,
          enable_heatmaps: false,
          rageclick: false,
          store_google: true,
        }}
      >
        <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
          <ChakraBaseProvider theme={theme}>
            <AmplitudeInitializer />
            <GoogleAnalyticsInitializer />
            <ClerkLoaded>
              <QueryClientProvider client={queryClient}>
                <UserDataInitializer />
                <RouterProvider router={router} />
              </QueryClientProvider>
            </ClerkLoaded>
            <ClerkLoading>
              <FullPageLoader />
            </ClerkLoading>
          </ChakraBaseProvider>
        </ErrorBoundary>
      </PostHogProvider>
    </ClerkProvider>
  )
}

export default App
