import {
  identify as amplitudeIdentify,
  setDeviceId as amplitudeSetDeviceId,
  track as amplitudeTrack,
} from "@amplitude/analytics-browser"
import { Identify } from "@amplitude/analytics-core"
import { useClerk } from "@clerk/clerk-react"
import { usePostHog } from "posthog-js/react"
import { useCallback } from "react"
import { useDeviceStore } from "../lib/stores/deviceStore"

const GTM_ID = "GTM-M6HPQ454"

type GTMEvent =
  | "recap_prescription"
  | "start_payment"
  | "payment_success"
  | "payment_failed"

type AnalyticsEvent =
  | "INFORM_START"
  | "INFORM_SUCCESS"
  | "CONSENT_START"
  | "CONSENT_SUCCESS"
  | "PROFILE_START"
  | "PROFILE_SUCCESS"
  | "AUTH_START"
  | "AUTH_SUCCESS"
  | "AUTH_FAIL"
  | "PRESCRIPTION_START"
  | "PRESCRIPTION_SUCCESS"
  | "PRESCRIPTION_FAIL"
  | "RESULTS_START"
  | "RESULTS_SUCCESS"
  | "PAYMENT_START"
  | "PAYMENT_SUCCESS"
  | "PAYMENT_FAIL"
  | "CALENDLY_START"
  | "CALENDLY_SUCCESS"
  | "CALENDLY_FAIL"
  | GTMEvent

/**
 * Tracks a page view event using Amplitude SDK
 * @param url - The URL of the page viewed
 */
export const pageview = (url: string) => {
  amplitudeTrack("Page View", { page: url })
}

// Define the default Amplitude endpoint
const DEFAULT_AMPLITUDE_ENDPOINT = "https://api.eu.amplitude.com"

/**
 * Function to determine the appropriate Amplitude endpoint
 */
export function getAmplitudeEndpoint() {
  return DEFAULT_AMPLITUDE_ENDPOINT
}

/**
 * Enhanced tracking function that handles both GTM and new analytics events
 */
export const gtmTrack = (
  event: GTMEvent,
  eventProperties?: Record<string, unknown>,
) => {
  const eventMapping: Record<GTMEvent, AnalyticsEvent> = {
    recap_prescription: "PRESCRIPTION_SUCCESS",
    start_payment: "PAYMENT_START",
    payment_success: "PAYMENT_SUCCESS",
    payment_failed: "PAYMENT_FAIL",
  }

  const mappedEvent = eventMapping[event]
  if (mappedEvent) {
    amplitudeTrack(mappedEvent, eventProperties)
  }

  if (typeof window.dataLayer !== "undefined") {
    // Always push the standard GTM event
    window.dataLayer.push({
      event,
      ...eventProperties,
      send_to: GTM_ID,
      event_category: "ecommerce",
      non_interaction: false,
    })

    // Also push GA4-specific event
    window.dataLayer.push({
      event: `ga4_${event}`,
      ...eventProperties,
      send_to: GTM_ID,
      event_category: "ecommerce",
      non_interaction: false,
    })

    /**
     * Additional ecommerce data for purchase events
     */
    if (event === "start_payment") {
      // Purchase start event
      window.dataLayer.push({
        event: "purchase_start",
        user_email: eventProperties?.email,
        ecommerce: {
          currency: "EUR",
          items: [
            {
              // Provide as much detail as needed
              item_id: eventProperties?.recordCode,
              price: eventProperties?.price,
              quantity: 1,
            },
          ],
        },
      })
      // GA4-specific purchase start event
      window.dataLayer.push({
        event: "ga4_purchase_start",
        user_email: eventProperties?.email,
        ecommerce: {
          currency: "EUR",
          items: [
            {
              item_id: eventProperties?.recordCode,
              price: eventProperties?.price,
              quantity: 1,
            },
          ],
        },
        send_to: GTM_ID,
      })
    }

    if (event === "payment_failed") {
      // Purchase fail event
      window.dataLayer.push({
        event: "purchase_fail",
        user_email: eventProperties?.email,
        ecommerce: {
          currency: "EUR",
          items: [
            {
              item_id: eventProperties?.recordCode,
              price: eventProperties?.price,
              quantity: 1,
            },
          ],
        },
      })
      // GA4-specific purchase fail event
      window.dataLayer.push({
        event: "ga4_purchase_fail",
        user_email: eventProperties?.email,
        ecommerce: {
          currency: "EUR",
          items: [
            {
              item_id: eventProperties?.recordCode,
              price: eventProperties?.price,
              quantity: 1,
            },
          ],
        },
        send_to: GTM_ID,
      })
    }

    if (event === "payment_success") {
      // Standard ecommerce success event
      window.dataLayer.push({
        event: "purchase",
        user_email: eventProperties?.email,
        ecommerce: {
          transaction_id: eventProperties?.sessionId,
          value: eventProperties?.price,
          currency: "EUR",
          items: [
            {
              item_id: eventProperties?.recordCode,
              price: eventProperties?.price,
              quantity: 1,
            },
          ],
        },
      })

      // GA4-specific purchase success event
      window.dataLayer.push({
        event: "ga4_purchase",
        user_email: eventProperties?.email,
        ecommerce: {
          transaction_id: eventProperties?.sessionId,
          value: eventProperties?.price,
          currency: "EUR",
          items: [
            {
              item_id: eventProperties?.recordCode,
              price: eventProperties?.price,
              quantity: 1,
            },
          ],
        },
        send_to: GTM_ID,
      })
    }
  } else if (import.meta.env.DEV) {
    console.log("GTM event:", { event, eventProperties })
  }
}

/**
 * Sets the user ID in Amplitude
 * @param userId - The unique identifier for the user
 */
export const setAmplitudeUserId = (userId: string) => {
  amplitudeIdentify(new Identify().set("user_id", userId))
  if (import.meta.env.DEV) {
    console.log("Amplitude user ID set to", userId)
  }
}

/**
 * Generates a UUID v4 with fallback for environments where crypto.randomUUID is not available
 * @returns string UUID
 */
function generateUUID(): string {
  // First try crypto.randomUUID()
  if (typeof crypto !== "undefined" && crypto.randomUUID) {
    return crypto.randomUUID()
  }

  // Fallback to manual UUID v4 generation
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (crypto.getRandomValues(new Uint8Array(1))[0] & 15) >> 0
    const v = c === "x" ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

function mapToGtmEvent(eventName: AnalyticsEvent): GTMEvent | null {
  switch (eventName) {
    case "PAYMENT_START":
      return "start_payment"
    case "PAYMENT_SUCCESS":
      return "payment_success"
    case "PAYMENT_FAIL":
      return "payment_failed"
    default:
      return null
  }
}

/**
 * Enhanced hook that provides unified tracking functionality
 */
export function useAmplitudeTrack() {
  const posthog = usePostHog()
  const { user } = useClerk()

  const track = useCallback(
    (
      eventName: AnalyticsEvent,
      eventProperties: Record<string, unknown> = {},
    ) => {
      // Retrieve email from Clerk or PostHog (must be set previously on PostHog via identify calls).
      const posthogDistinctId = posthog?.get_distinct_id?.()
      const posthogKnownEmail = posthog?.get_property?.("email") // or your configured property key
      const clerkEmail =
        user?.primaryEmailAddress?.emailAddress ||
        user?.emailAddresses?.[0]?.emailAddress

      const currentEmail = clerkEmail || posthogKnownEmail
      const deviceState = useDeviceStore.getState()

      // Ensure device ID
      if (!deviceState.deviceId) {
        const newDeviceId = generateUUID()
        deviceState.setDeviceId(newDeviceId)
      }

      const baseProperties = {
        ...eventProperties,
        timestamp: new Date().toISOString(),
        event_type: eventName,
        session_id: generateUUID(),
        is_first_visit: !deviceState.firstVisit,
        last_visit_time: deviceState.lastVisit,
        device_id: deviceState.deviceId,
        distinct_id: posthogDistinctId,
        user_email: currentEmail, // pass the resolved email to analytics platforms
        $set: {
          last_event: eventName,
          last_event_time: new Date().toISOString(),
        },
      }

      const gtmEvent = mapToGtmEvent(eventName)
      if (gtmEvent) gtmTrack(gtmEvent, baseProperties)

      // Track in Amplitude
      amplitudeTrack(eventName, {
        ...baseProperties,
        user_id: posthogDistinctId,
      })

      // Track in PostHog
      posthog.capture(eventName, {
        ...baseProperties,
        user_id: user?.id || posthogDistinctId,
      })

      // Update visit timestamps
      if (!deviceState.firstVisit)
        deviceState.setFirstVisit(Date.now().toString())
      deviceState.setLastVisit(Date.now().toString())

      if (import.meta.env.DEV) {
        console.log("[Analytics Event]", {
          event: eventName,
          properties: baseProperties,
          destinations: ["Amplitude", "PostHog"],
        })
      }
    },
    [posthog, user],
  )

  const identify = useCallback((userId: string) => {
    const identifyInstance = new Identify().set("user_id", userId)
    amplitudeIdentify(identifyInstance)

    if (import.meta.env.DEV) {
      console.log("Amplitude identify", userId)
    }
  }, [])

  return { track, identify }
}

/**
 * Ensures a device ID is set in the store and Amplitude.
 */
export function initializeDevice() {
  const { deviceId, setDeviceId } = useDeviceStore.getState()

  if (!deviceId) {
    const newDeviceId = generateUUID()
    setDeviceId(newDeviceId)
    setAmplitudeUserDevice(newDeviceId)
  }
}

/**
 * Sets the device ID in Amplitude.
 * @param installationToken - The device ID to set.
 */
export const setAmplitudeUserDevice = (installationToken: string) => {
  amplitudeSetDeviceId(installationToken)
}

/**
 * Standalone tracking function that can be used without React hooks
 * @param eventName - The name of the event to track
 * @param eventProperties - Additional properties to include with the event
 */
export function trackEvent(
  eventName: AnalyticsEvent,
  eventProperties?: Record<string, unknown>,
) {
  try {
    const deviceState = useDeviceStore.getState()

    // Ensure device ID is set
    if (!deviceState.deviceId) {
      const newDeviceId = generateUUID()
      deviceState.setDeviceId(newDeviceId)
    }

    const trackingProperties = {
      ...eventProperties,
      timestamp: new Date().toISOString(),
      event_type: eventName,
      session_id: generateUUID(),
      is_first_visit: !deviceState.firstVisit,
      last_visit_time: deviceState.lastVisit,
      device_id: deviceState.deviceId,
      $set: {
        last_event: eventName,
        last_event_time: new Date().toISOString(),
      },
    }

    // Track in Amplitude
    amplitudeTrack(eventName, trackingProperties)

    // Update visit timestamps
    if (!deviceState.firstVisit) {
      deviceState.setFirstVisit(Date.now().toString())
    }
    deviceState.setLastVisit(Date.now().toString())

    if (import.meta.env.DEV) {
      console.log("[Analytics Event]", {
        event: eventName,
        properties: trackingProperties,
        destinations: ["Amplitude"],
      })
    }
  } catch (error) {
    console.error("[Analytics Error] Failed to track event:", error)
  }
}
